import React from "react"
import Styles from "./privacy-policy.module.css"

import Layout from "../components/layout"
import SEO from "../components/seo"

import hearingImage from "../images/hearing-assessment.png"
import pricingImage from "../images/pricing-screen.png"
import playImage from "../images/play-screen.png"
import beforeImage from "../images/audiocardio-before.png"

import cat705 from "../gallery/cat705_ast.jpg"
import dog99 from "../gallery/dog99_ast.jpg"
import cat838_m from "../gallery/cat838_m.png"
import cat838 from "../gallery/cat838.png"

import launchData from "../images/launch-data.png"
import currentData from "../images/current-data.png"

import volume80 from "../ups/volume80.jpg"
import volume60 from "../ups/volume60.jpg"

const Page = () => (
  <Layout>
    <SEO title="Nick Treadway | Product Designer" index="noindex" />
    <div className={`${Styles.policy} lg:text-lg container mt-48 md:mt-64`}>
      <h1>Nick Treadway | Product Design</h1>
      <h2>Project: Paws &amp; Tails AI</h2>
      <p className="my-2">
        This project came about as a marketing idea for my AI agency. Having
        recently lost my cats I also wanted away to memorialize them.
      </p>
      <p className="my-2">
        The project has since turned into a product that can stand by itself and
        has given me new ideas on how machine learning can be used to design and
        enhance products going forward.
      </p>
      <h3>Product design included</h3>
      <span className={`${Styles.nick}`}>
        <ul>
          <li>Product idea creation</li>
          <li>Data creation and refinement for machine learning</li>
          <li>Team management of 1 designer and 1 AI engineer</li>
          <li>Micro site design</li>
          <li>Micro site development</li>
          <li>Social Media marketing</li>
          <li>SEO and Analytics</li>
        </ul>
      </span>
      <h3>Code samples:</h3>
      <span className={`${Styles.nick}`}>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-teal-500"
              href="https://gist.github.com/ntreadway/3000ae15f54421928683cfc07307a47a"
            >
              index.js
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-teal-500"
              href="https://gist.github.com/ntreadway/3a1da3f3553f22dcacaaab1a3cb182f6"
            >
              header.js
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-teal-500"
              href="https://gist.github.com/ntreadway/39feb40512fc615f0055b78b064d4a2a"
            >
              header.module.css
            </a>
          </li>
        </ul>
      </span>
      <h3>Design samples:</h3>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/4 border-black border-solid border-2 mx-2 my-2 bg-black rounded shadow-2xl">
          <img alt="Hearing assessment" src={cat838_m} />
        </div>
        <div className="w-full md:w-1/4 border-black border-solid border-2 mx-2 my-2 bg-black rounded shadow-2xl">
          <img alt="Start Therapy" src={cat838} />
        </div>
        <div className="w-full md:w-1/4 border-black border-solid border-2 mx-2 my-2 rounded shadow-2xl">
          <img alt="Subscription Pricing" src={cat705} />
        </div>
        <div className="w-full md:w-1/4 border-black border-solid border-2 mx-2 my-2 rounded shadow-2xl">
          <img alt="Subscription Pricing" src={dog99} />
        </div>
      </div>
      <h3>Heatmap Data:</h3>
      <div className="flex flex-wrap">
        <div className="border-black border-solid border-2 mx-2 my-2 bg-black rounded shadow-2xl">
          <img alt="Hearing assessment" src={launchData} />
        </div>
        <div className="border-black border-solid border-2 mx-2 my-2 bg-black rounded shadow-2xl">
          <img alt="Start Therapy" src={currentData} />
        </div>
      </div>
      <hr className="my-4" />
      <h2>Project: Audio Cardio</h2>
      <p className="my-2">
        When I was contacted by Audio Cardio for consulting their iOS app was in
        serious trouble. Having had multiple offshore developers working on the
        project lead to their codebase becoming unmaintainable, making
        development slow and buggy. Also the applications onboarding and
        subscription rates were in need of serious improvements.
      </p>
      <p className="my-2">
        I took the role of a CTO / product lead and got their codebase into a
        working organized state. Then I focused on redesigning onboarding to
        address their pain points. Finally I updated their subscription flow
        increasing it buy 30%.
      </p>

      <h3>Product design included:</h3>
      <span className={`${Styles.nick}`}>
        <ul>
          <li>Onboarding design</li>
          <li>Onboarding development using Swift 5</li>
          <li>Team management of 1 backend engineer</li>
          <li>Technical advising</li>
          <li>Designing and implementation API changes</li>
          <li>Design and implementation of the new subscription process</li>
        </ul>
      </span>
      <h3>Design samples:</h3>
      <h4 className="my-2">Before:</h4>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/4 border-black border-solid border-2 mx-2 my-2 rounded shadow-2xl">
          <img alt="Hearing assessment" src={beforeImage} />
        </div>
      </div>
      <h4 className="my-2">After:</h4>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/4 border-black border-solid border-2 mx-2 my-2 rounded shadow-2xl">
          <img alt="Hearing assessment" src={hearingImage} />
        </div>
        <div className="w-full md:w-1/4 border-black border-solid border-2 mx-2 my-2 rounded bg-gray-900 shadow-2xl">
          <img alt="Start Therapy" src={playImage} />
        </div>
        <div className="w-full md:w-1/4 border-black border-solid border-2 mx-2 my-2 rounded shadow-2xl">
          <img alt="Subscription Pricing" src={pricingImage} />
        </div>
      </div>
      <hr className="my-4" />
      <h2>Project: UPS Truck Trailer Volume Detection</h2>
      <p className="my-2">
        UPS had a problem estimating how full trailers are which was costing
        them about 3K a trailer. Their current process of measuring volume was
        the human eye and everyone had their own "official" measurement system.
      </p>
      <p className="my-2">
        The project also had a number of constraints which included no internet availability, a high traffic environment which made it impossible to
        collect data, and limited access to the
        operations facility for testing. Also the IOT requirements needed the
        application to run on an Android device.
      </p>
      <p className="my-2">
        I took the role of a product design lead and designed a solution
        harnessing CNN models. We trained with image semantic segmentation and
        made it more sensitive to packages in a 2d environment. Then trained
        the model using 3D bounding box estimation to give it the human
        capability of 3D concepts in 2D images. We also created a diverse data set to
        train the model to predict the percentage of interior volume using the Unity game engine. Finally we
        moved the model to the ML kit to make it work locally
        and natively on the Android device using augmented reality.
      </p>

      <h3>Product design included:</h3>
      <span className={`${Styles.nick}`}>
        <ul>
          <li>Data augmentation using the Unity game engine</li>
          <li>Facility testing</li>
          <li>Team management of 1 AI engineer</li>
          <li>Technical advising</li>
          <li>Design and implementation of AR features</li>
          <li>Android development</li>
        </ul>
      </span>
      <h3>Design samples:</h3>
      <h4 className="my-2">App:</h4>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/4 border-black border-solid border-2 mx-2 my-2 rounded shadow-2xl">
          <img alt="Hearing assessment" src={volume80} />
        </div>
        <div className="w-full md:w-1/4 border-black border-solid border-2 mx-2 my-2 rounded shadow-2xl">
          <img alt="Subscription Pricing" src={volume60} />
        </div>
      </div>
    </div>
  </Layout>
)

export default Page
